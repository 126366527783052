import React, { Suspense, useEffect, useState, useRef } from 'react';
import { Helmet } from "react-helmet";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LeadGenerator from "../components/GenerateLead";
import StyledHero from "../components/StyledHero";
import Banner from "../components/Banner";
import FaqAccordion from "../components/FaqAccordion";
import Subscribe from "../components/Subscribe";
import RequestCallback from "../components/RequestCallback";
import axios from "axios";
import parser from "html-react-parser";

import ContactImg from "../images/contact-us-banner.jpg";

import "../css/contactUs.css";

export default function Contact({
  isSWMC,
  setLoanPurpose,
  setModalShow,
  setOfferLoanType,
})

{
  const ref = useRef();
  const [isIntersecting, setIntersecting] = useState(false);
  const [footerInfoData, setFooterInfoData] = useState();

  useEffect(() => {
    /**
     * @description Fetches the SWMC footer data stored in master JSON in the S3 bucket.
     * @returns JSON Object of the requested file
     */
    const fetchSWMCFooterInfo = async () => {
      try {
        await axios
          .get(process.env.REACT_APP_SWMC_FOOTET_INFO)
          .then((response) => {
            //console.log(response.data);
            setFooterInfoData(response.data);
            const observer = new IntersectionObserver(([entry]) => {
              if (entry.isIntersecting) {
                setIntersecting(entry.isIntersecting);
                observer.disconnect();
              }
            });
            observer.observe(ref.current);
            // Remove the observer as soon as the component is unmounted
            return () => {
              observer.disconnect();
            };
          })
          .catch((error) => {
            console.log(error);
          });
      } catch (e) {
        console.log(e);
      }
    };

    fetchSWMCFooterInfo();
  }, []);

  
  return (
    <div className="contact-wrapper">
      <Helmet>
        <title>Contact Us | Sun West</title>
        <meta
          name="description"
          content="Sun West is always here to answer questions for new and returning clients."
        />
      </Helmet>
      <StyledHero img={ContactImg}>
        <Banner
          title="CONTACT US"
          headerFooter={true}
          isSWMC={isSWMC}
          setLoanPurpose={setLoanPurpose}
          setModalShow={setModalShow}
          setOfferLoanType={setOfferLoanType}
        ></Banner>
      </StyledHero>
      {footerInfoData ? (
            <>
      <Container>
        <Row>
          <Col className="py-5">
            <h2 className="text-center">Contact Us</h2>
            <p className="text-center">
              Sun West Corporate Headquarters
              <br />
              <a
             href={
              footerInfoData.contactUs.address.googleMapsUrl
            }
                target="_blank"
              >
                {footerInfoData.contactUs.address.fullAddress}
              </a>
              <br />
              Phone : <a href="tel:(562) 924-7884">(562) 924-7884</a>
              <br />
              Toll Free: <a href="tel:(800) 453-7884">(800) 453-7884</a>
              <br />
              Fax : <a href="fax:(562) 924-6057">(562) 924-6057</a>
            </p>
          </Col>
        </Row>
      </Container>
      </>
          ) : (
            ''
          )}
      <Container id="ContactLead">
        <LeadGenerator
          isSWMC={isSWMC}
          title=""
          loanPurpose={""}
          offerLoanType={""}
        />
        <a
          className="phoneLink"
          href="tel:+18447869378"
          target="_blank"
          rel="nooppener"
        >
          (844) SUN-WEST
        </a>
      </Container>
     
      <FaqAccordion />
      <Col sm={12} className="mt-5 pt-5 px-0">
        <RequestCallback className="mt-5" />
      </Col>
      <Col sm={12}>
        <Subscribe />
      </Col>
    </div>
  );
}
