import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";

import "../css/joinUs.css"

export default function JoinsUs() {
  return (
    <Container fluid id="joinus-wrapper">
      <Row>
        <div className="fl-row-content-wrap">
          <div className="uabb-row-separator uabb-top-row-separator"></div>
          <div className="fl-row-content fl-row-full-width fl-node-content">
            <div
              className="fl-col-group fl-node-5bcdc302aa160"
              data-node="5bcdc302aa160"
            >
              <div
                className="fl-col fl-node-5bcdc302aa27a fl-col-has-cols"
                data-node="5bcdc302aa27a"
              >
                <div className="fl-col-content fl-node-content">
                  <div
                    className="fl-col-group fl-node-5bcdc7dacdeab fl-col-group-nested fl-col-group-equal-height fl-col-group-align-top"
                    data-node="5bcdc7dacdeab"
                  >
                    <div
                      className="fl-col fl-node-5bcdc7dacdff0"
                      data-node="5bcdc7dacdff0"
                    >
                      <div className="fl-col-content fl-node-content"></div>
                    </div>
                    <div
                      className="fl-col fl-node-5bcdc7dace02c fl-col-small"
                      data-node="5bcdc7dace02c"
                    >
                      <div className="fl-col-content fl-node-content">
                        <div
                          id="back-img-sec"
                          className="fl-module fl-module-info-box fl-node-5bd1621911321"
                          data-node="5bd1621911321"
                        >
                          <div className="fl-module-content fl-node-content">
                            <div className="uabb-module-content uabb-infobox infobox-center ">
                              <div className="uabb-infobox-left-right-wrap">
                                <div className="uabb-infobox-content">
                                  <div className="uabb-infobox-title-wrap">
                                    <h3 className="uabb-infobox-title">
                                      <div className="small-text">
                                        START YOUR CAREER WITH
                                      </div>
                                      <div className="head-sec-home">
                                        SUN WEST
                                      </div>
                                    </h3>
                                  </div>
                                  <div className="uabb-infobox-text-wrap">
                                    <div className="uabb-infobox-text uabb-text-editor"></div>
                                    <div className="uabb-infobox-button">
                                      <div className="uabb-module-content uabb-button-wrap uabb-creative-button-wrap uabb-button-width-auto uabb-creative-button-width-auto">
                                        <a
                                          href="https://www.swmc.com/careers"
                                          target="_blank"
                                          className="uabb-button uabb-creative-button uabb-creative-flat-btn  uabb-none-btn  btn-sec"
                                          role="button"
                                        >
                                          <span className="uabb-button-text uabb-creative-button-text">
                                            JOIN US
                                          </span>
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="fl-col fl-node-5bcdc7dace065 fl-col-small"
                      data-node="5bcdc7dace065"
                    >
                      <div className="fl-col-content fl-node-content"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="fl-col-group fl-node-5bcdc8eb9dde3"
              data-node="5bcdc8eb9dde3"
            >
              <div
                className="fl-col fl-node-5bcdc8eb9e014 fl-col-has-cols"
                data-node="5bcdc8eb9e014"
              >
                <div className="fl-col-content fl-node-content">
                  <div
                    className="fl-col-group fl-node-5c5bd8f368501 fl-col-group-nested"
                    data-node="5c5bd8f368501"
                  >
                    <div
                      className="fl-col fl-node-5bceb81360288 fl-col-small"
                      data-node="5bceb81360288"
                    >
                      <div className="fl-col-content fl-node-content"></div>
                    </div>
                    <div
                      className="fl-col fl-node-5c5bd9169f435"
                      data-node="5c5bd9169f435"
                    >
                      <div className="fl-col-content fl-node-content">
                        <div
                          id="text-styl"
                          className="fl-module fl-module-rich-text fl-node-5bcdc931d8dc1"
                          data-node="5bcdc931d8dc1"
                        >
                          <div className="fl-module-content fl-node-content">
                            <div className="fl-rich-text">
                              <p className="text-center">
                                <span className="text-white">
                                  Gain access to unique benefits such as
                                  national sales coaching, leads generation,
                                  21st century technology, and 24/7/365 back-end
                                  loan processing so you can focus on your
                                  clients and their needs instead of paperwork!
                                  We are growing rapidly in this unique market
                                  and inviting top-notch professionals to
                                  participate in our success
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="fl-col fl-node-5c5bd90ed4fe5 fl-col-small"
                      data-node="5c5bd90ed4fe5"
                    >
                      <div className="fl-col-content fl-node-content"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Row>
    </Container>
  );
}
