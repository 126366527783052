import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "../css/HowItWorks.css";

export default function HowItWorks() {
  return (
    <div id="howItWorks-wrapper">
      <Container className="my-5">
        <Row>
          <Col className="text-center mb-4 pb-1" lg={12}>
            <h2
              style={{
                fontSize: "32px",
              }}
            >
              How It Works
            </h2>
          </Col>
          <Col
            sm={12}
            md={6}
            lg={3}
            style={{
              margin: "15px 0 0 0",
              padding: "0 7.5px",
            }}
          >
            <div
              style={{
                justifyContent: "center",
                textAlign: "center",
                border: "2px solid #2d3691",
                overflow: "visible",
                padding: "10px",
                height: "100%",
              }}
            >
              <img
                src="https://resources.swmc.com/swmc-images/VA/1.png"
                width="89"
                height="89"
                alt="Notebook"
                style={{ margin: "-40px auto 0 auto" }}
              />
              <h2
                style={{
                  fontSize: "32px",
                  margin: "10px 0",
                  padding: "0 0 10px 0",
                  textAlign: "center",
                }}
              >
                Step One
              </h2>
              <p className="mb-1">
                Use our online application to send in your request or contact
                our office via phone or email.
              </p>
            </div>
          </Col>
          <Col
            sm={12}
            md={6}
            lg={3}
            style={{
              margin: "15px 0 0 0",
              padding: "0 7.5px",
            }}
          >
            <div
              style={{
                justifyContent: "center",
                textAlign: "center",
                border: "2px solid #2d3691",
                overflow: "visible",
                padding: "10px",
                height: "100%",
              }}
            >
              <img
                src="https://resources.swmc.com/swmc-images/VA/2_icon.png"
                width="89"
                height="89"
                alt="Notebook"
                style={{ margin: "-40px auto 0 auto" }}
              />
              <h2
                style={{
                  fontSize: "32px",
                  margin: "10px 0",
                  padding: "0 0 10px 0",
                  textAlign: "center",
                }}
              >
                Step Two
              </h2>
              <p className="mb-1">
                Once your application is complete, we&#39;ll take a look at your
                family&#39;s financial situation and home loan needs and then
                pair you with a list of mortgage options.
              </p>
            </div>
          </Col>
          <Col
            sm={12}
            md={6}
            lg={3}
            style={{
              margin: "15px 0 0 0",
              padding: "0 7.5px",
            }}
          >
            <div
              style={{
                justifyContent: "center",
                textAlign: "center",
                border: "2px solid #2d3691",
                overflow: "visible",
                padding: "10px",
                height: "100%",
              }}
            >
              <img
                src="https://resources.swmc.com/swmc-images/VA/3_icon.png"
                width="89"
                height="89"
                alt="Clipboard"
                style={{ margin: "-40px auto 0 auto" }}
              />
              <h2
                style={{
                  fontSize: "32px",
                  margin: "10px 0",
                  padding: "0 0 10px 0",
                  textAlign: "center",
                }}
              >
                Step Three
              </h2>
              <p className="mb-1">
                Review and discuss your loan options with your loan officer to
                make sure you choose the right loan for your needs.
              </p>
            </div>
          </Col>
          <Col
            sm={12}
            md={6}
            lg={3}
            style={{
              margin: "15px 0 0 0",
              padding: "0 7.5px",
            }}
          >
            <div
              style={{
                justifyContent: "center",
                textAlign: "center",
                border: "2px solid #2d3691",
                overflow: "visible",
                padding: "10px",
                height: "100%",
              }}
            >
              <img
                src="https://resources.swmc.com/swmc-images/VA/5_icon.png"
                width="89"
                height="89"
                alt="Home"
                style={{ margin: "-40px auto 0 auto" }}
              />
              <h2
                style={{
                  fontSize: "32px",
                  margin: "10px 0",
                  padding: "0 0 10px 0",
                  textAlign: "center",
                }}
              >
                Step Four
              </h2>
              <p className="mb-1">
                Once your loan is chosen and your application is accepted-you
                are ready to close!
              </p>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
