import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import StyledHero from "../components/StyledHero";
import Banner from "../components/Banner";
import HowItWorks from "../components/HowItWorks";
import FaqAccordion from "../components/FaqAccordion";
import Subscribe from "../components/Subscribe";
import RequestCallback from "../components/RequestCallback";

import ConventionalImg from "../images/Conventional-Loan-Page-Image.jpg";

export default function Conventional({
  isSWMC,
  setLoanPurpose,
  setModalShow,
  setOfferLoanType,
}) {
  return (
    <div id="productLoans">
      <Helmet>
        <title>Conventional Loans | Sun West</title>
        <meta
          name="description"
          content="A conventional loan is a mortgage that is non-government funded. They are either conforming or non-conforming. Find out if a conventinal loan is for you!"
        />
      </Helmet>
      <StyledHero img={ConventionalImg} bgPosition="center center">
        <Banner
          title="Conventional Loans"
          headerFooter={false}
          isSWMC={isSWMC}
          setLoanPurpose={setLoanPurpose}
          setModalShow={setModalShow}
          setOfferLoanType={setOfferLoanType}
        ></Banner>
      </StyledHero>
      <Container>
        <Row>
          <Col id="page-wrapper" className="pt-5">
            <h2>What is a Conventional Loan?</h2>
            <p>
              A conventional loan is a mortgage that is not guaranteed or
              insured by any government agency such as FHA, VA, or USDA.
              Conventional loans are either conforming or non-conforming.
              Conforming mortgages must follow lending guidelines and loan
              limits set by Fannie Mae or Freddie Mac, whereas{" "}
              <Link to="../jumbo-loans">Non-conforming mortgages</Link> have
              loan amounts higher than the loan limits set by Fannie Mae /
              Freddie Mac.
            </p>
            <p>
              Conventional loans generally offer low interest rate and fees,
              which can result in lower monthly payments. Home buyers can also
              choose a fixed-rate over an adjustable-rate mortgage to avoid any
              rising mortgage rates which makes budgeting much easier.
            </p>
            <p>&nbsp;</p>
            <h2>Eligibility</h2>
            <p>
              There are many benefits to a conventional loan such as faster home
              loan process, flexibility, and lower costs. Conventional loans can
              be a great option for home buyers who can meet these requirements:
            </p>
            <ol>
              <li>Be able to make a down payment</li>
              <li>Have a decent credit score (620 or above)</li>
              <li>Prove a stable income</li>
            </ol>
            <p>
              If you meet these requirements, you may be able to qualify for a
              conventional loan.
            </p>
            <p>&nbsp;</p>
            <h2>Why should you choose Sun West?</h2>
            <p>
              Our team offers a 24-hour loan center for future home owners who
              don&#39;t have time to apply for a loan during regular business hours.
              We offer quick loan approvals and provide our clients with
              suitable loan options for their needs and financial situations.
              Our team members are well-versed in conventional loan rules and
              requirements and will ensure that you are knowledgeable about{" "}
              <Link to="../our-mortgage-process">our mortgage process</Link>{" "}
              prior to committing to a loan.
            </p>
            <p>
              For any questions about the conventional loan process or any of
              our other home loan services, give our team at Sun West a call to
              speak to a local team member who can answer all of your home loan
              questions. <Link to="../contact-us">Contact us today!</Link>
            </p>
          </Col>
        </Row>
      </Container>
      <div className="my-5 pb-2 px-0">
        <HowItWorks />
      </div>
      <FaqAccordion />
      <Col sm={12} className="mt-5 pt-5 px-0">
        <RequestCallback className="mt-5" />
      </Col>
      <Col sm={12}>
        <Subscribe />
      </Col>
    </div>
  );
}
