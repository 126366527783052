import React from "react";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { faCalculator } from '@fortawesome/free-solid-svg-icons'
import { faDollarSign } from '@fortawesome/free-solid-svg-icons'

export default function Resources() {
  return (
    <>
      <div
        id="resource-sec"
        className="fl-row fl-row-full-width fl-row-bg-none fl-node-5bd040003ecc4"
        data-node="5bd040003ecc4"
      >
        <div className="fl-row-content-wrap">
          <div className="uabb-row-separator uabb-top-row-separator"></div>
          <div className="fl-row-content fl-row-full-width fl-node-content">
            <div
              className="fl-col-group fl-node-5bd0400049878"
              data-node="5bd0400049878"
            >
              <div
                className="fl-col fl-node-5bd0400049a96 fl-col-has-cols"
                data-node="5bd0400049a96"
              >
                <div className="fl-col-content fl-node-content">
                  <div
                    className="fl-module fl-module-advanced-separator fl-node-5bd041568838f"
                    data-node="5bd041568838f"
                  >
                    <div className="fl-module-content fl-node-content">
                      <div className="uabb-module-content uabb-separator-parent">
                        <div className="uabb-separator-wrap uabb-separator-center ">
                          <div className="uabb-separator-line uabb-side-left">
                            <span></span>
                          </div>

                          <div className="uabb-divider-content uabbi-divider">
                            <p className="uabb-divider-text">
                              <b>Resources</b>
                            </p>{" "}
                          </div>

                          <div className="uabb-separator-line uabb-side-right">
                            <span></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="fl-col-group fl-node-5bd0420e5103c fl-col-group-nested"
                    data-node="5bd0420e5103c"
                  >
                    <div
                      className="fl-col fl-node-5bd0420e5128f fl-col-small"
                      data-node="5bd0420e5128f"
                    >
                      <div className="fl-col-content fl-node-content">
                        <div
                          className="fl-module fl-module-pp-infobox fl-node-5bd04875cbe62"
                          data-node="5bd04875cbe62"
                        >
                          <div className="fl-module-content fl-node-content">
                            <div className="pp-infobox-wrap">
                              <Link
                                className="pp-more-link"
                                to="../home-buyers-guide">
                                <div className="pp-infobox layout-1">
                                  <div className="pp-heading-wrapper">
                                    <div className="pp-icon-wrapper animated">
                                      <div className="pp-infobox-icon">
                                        <div className="pp-infobox-icon-inner">
                                          <span className="pp-icon"><FontAwesomeIcon icon={faHome} /></span>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="pp-heading-wrapper-inner">
                                      <span className="pp-infobox-title-prefix"></span>

                                      <div className="pp-infobox-title-wrapper">
                                        <h4 className="pp-infobox-title">
                                          Home Buyer Guide
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="pp-infobox-description">
                                    <div className="pp-description-wrap"></div>
                                  </div>
                                </div>{" "}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="fl-col fl-node-5bd0420e512d0 fl-col-small"
                      data-node="5bd0420e512d0"
                    >
                      <div className="fl-col-content fl-node-content">
                        <div
                          className="fl-module fl-module-pp-infobox fl-node-5bd049162a15d"
                          data-node="5bd049162a15d"
                        >
                          <div className="fl-module-content fl-node-content">
                            <div className="pp-infobox-wrap">
                              <a
                                className="pp-more-link"
                                href="https://www.swmc.com/angelai/calculators"
                                target="_blank"
                                rel="nofollow noreferrer"
                              >
                                <div className="pp-infobox layout-1">
                                  <div className="pp-heading-wrapper">
                                    <div className="pp-icon-wrapper animated">
                                      <div className="pp-infobox-icon">
                                        <div className="pp-infobox-icon-inner">
                                          <span className="pp-icon"><FontAwesomeIcon icon={faCalculator} /></span>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="pp-heading-wrapper-inner">
                                      <span className="pp-infobox-title-prefix"></span>

                                      <div className="pp-infobox-title-wrapper">
                                        <h4 className="pp-infobox-title">
                                          Mortgage Calculators
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="pp-infobox-description">
                                    <div className="pp-description-wrap"></div>
                                  </div>
                                </div>{" "}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="fl-col fl-node-5bd0420e5130d fl-col-small"
                      data-node="5bd0420e5130d"
                    >
                      <div className="fl-col-content fl-node-content">
                        <div
                          className="fl-module fl-module-pp-infobox fl-node-5bd0475f2843e"
                          data-node="5bd0475f2843e"
                        >
                          <div className="fl-module-content fl-node-content">
                            <div className="pp-infobox-wrap">
                            <Link
                                className="pp-more-link"
                                to="../refinancing-guide">
                                <div className="pp-infobox layout-1">
                                  <div className="pp-heading-wrapper">
                                    <div className="pp-icon-wrapper animated">
                                      <div className="pp-infobox-icon">
                                        <div className="pp-infobox-icon-inner">
                                          <span className="pp-icon"><FontAwesomeIcon icon={faDollarSign} /></span>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="pp-heading-wrapper-inner">
                                      <span className="pp-infobox-title-prefix"></span>

                                      <div className="pp-infobox-title-wrapper">
                                        <h4 className="pp-infobox-title">
                                          Home Refinance Guide
                                        </h4>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="pp-infobox-description">
                                    <div className="pp-description-wrap"></div>
                                  </div>
                                </div>{" "}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
