import React, {useState} from 'react';
import { HashLink } from 'react-router-hash-link';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import HeaderFooter from './HeaderFooter';
import { useEffect } from 'react';

export default function Banner(props) {
  const [buttonColor, setButtonColor] = useState('');
  const [bannerLoanType, setBannerLoanType] = useState('');
  const [bannerLoanPurpose, setBannerLoanPurpose] = useState('');

  useEffect(()=>{
    if(!props.isSWMC) {
      setButtonColor('#2d3691');
    } else {
      setButtonColor('#00aae4');
    }

    switch (props.title) {
      case "Home Buyer's Guide":
        setBannerLoanType("");
        setBannerLoanPurpose("PURCHASE");
        break;
      case "Conventional Loans":
        setBannerLoanType("200");
        setBannerLoanPurpose("");
        break;
      case "FHA Loans":
        setBannerLoanType("110");
        setBannerLoanPurpose("");
        break;
      case "Refinancing":
        setBannerLoanType("");
        setBannerLoanPurpose("LIMITED CO");
        break;
      case "Refinancing Guide":
        setBannerLoanType("");
        setBannerLoanPurpose("LIMITED CO");
        break;
      case "VA Loans":
        setBannerLoanType("120");
        setBannerLoanPurpose("");
        break;
      default:
        setBannerLoanType("");
        setBannerLoanPurpose("");
        break;
    }
  },[props.title])

  return (
    <>
      <Container fluid className='px-0' style={{ overflowX: 'hidden' }}>
        <Row className='no-gutters'>
          <Col sm={12}>
            <div id='banner-content'>
              <div
                style={{
                  maxWidth: '1240px',
                  margin: '85px auto',
                  padding: '40px',
                }}>
                {!props.isSWMC &&
                  <h1
                    className='justify-content-center justify-content-md-start p-0 m-0'
                    style={{
                      display: 'flex',
                      fontSize: 'clamp(20px, 5.33vw, 50px)',
                      color: '#FFFFFF',
                      minHeight: '76px',
                      alignItems: 'center',
                      fontFamily: 'trajan-pro-bold'
                    }}>
                    {props.title}
                  </h1>
                }
                <div
                  className='justify-content-center justify-content-md-start'
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                  }}>
                  <button
                    onClick={() => {
                      props.setLoanPurpose(bannerLoanPurpose);
                      props.setModalShow(true);
                      props.setOfferLoanType(bannerLoanType);
                    }}
                    className='text-decoration-none'
                    style={{
                      display: 'flex',
                      backgroundColor: buttonColor,
                      fontSize: '18px',
                      minWidth: '260px',
                      width: '260px',
                      height: '44px',
                      color: '#FFFFFF',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer',
                      border: 'none',
                      margin: '5px',
                    }}>
                    {!props.isSWMC ? 'APPLY HERE' : 'FREE RATE QUOTE'}
                  </button>
                  <HashLink
                    smooth
                    to='#requestCallbackWrapper'
                    role='button'
                    className='text-decoration-none'
                    style={{
                      display: 'flex',
                      backgroundColor: buttonColor,
                      fontSize: '18px',
                      minWidth: '260px',
                      width: '260px',
                      height: '44px',
                      color: '#FFFFFF',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer',
                      margin: '5px',
                    }}>
                    REQUEST A CALLBACK
                  </HashLink>
                </div>
              </div>
            </div>
            {props.headerFooter ? <HeaderFooter isSWMC={props.isSWMC}/> : null}
          </Col>
        </Row>
      </Container>
    </>
  );
}
