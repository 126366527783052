import React from 'react';
import { Helmet } from "react-helmet";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import StyledHero from '../components/StyledHero';
import Banner from '../components/Banner';
import HowItWorks from "../components/HowItWorks";
import FaqAccordion from "../components/FaqAccordion";
import Subscribe from "../components/Subscribe";
import RequestCallback from "../components/RequestCallback";

import OurProcessImg from "../images/our-mortgage-process-banner.jpg"

export default function OurProcess({
  isSWMC,
  setLoanPurpose,
  setModalShow,
  setOfferLoanType,
}) {

  return (
    <div>
      <Helmet>
        <title>Our Mortgage Process | Sun West</title>
        <meta
          name="description"
          content="At Sun West, we want to educate our clients on the mortgage process in as clear and efficient way as possible."
        />
      </Helmet>
      <StyledHero img={OurProcessImg} bgPosition="bottom center">
          <Banner title="Our Mortgage Process" headerFooter={true}
          isSWMC={isSWMC}
          setLoanPurpose={setLoanPurpose}
          setModalShow={setModalShow}
          setOfferLoanType={setOfferLoanType}>
          </Banner>
      </StyledHero> 

      <Container>
        <Row>
            <Col id="OurProcess-wrapper" className="pt-5">
	            <h2>Our Mortgage Process</h2>
                <p>At Sun West, we want to educate our clients on the mortgage process in as clear and efficient way as possible. To prevent leaving any of our clients in the dark, we created a detailed list to explain how the mortgage loan process works with Sun West.</p>
                <h3>1. Start Your Application</h3>
                <p>Our team offers several ways to apply for a new home loan. Use our <button className="btn-link" onClick={()=>{
                  setLoanPurpose('');
                  setModalShow(true);
                  setOfferLoanType('');
                }}>online application</button> to send in your request or contact our office via phone or email. We&#39;ll match you up with one of our team members who will get you started on the mortgage loan process. Prepare small details like your mortgage loan goals, household income, and total assets to share with our team and we&#39;ll prepare your options.</p>
                <h3>2. Review Your Options with a Member of Our Team</h3>
                <p>Once your application is complete, we&#39;ll take a look at your family&#39;s financial situation and home loan needs and pair you with a list of home loans that fits the cost, rate, and benefits you&#39;ll need.</p>
                <h3>3. Confirm Your Information</h3>
                <p>A member of our team will review your financial information and confirm that everything looks good. Once everything is confirmed, we will move forward with the application.</p>
                <h3>4. Close on Your New Loan</h3>
                <p>Once your application is accepted, you are ready to close your loan. Whether you&#39;re ready that same day or need extra time, our loan officers will always be available to you whenever you choose to close on your new loan.</p>
                <h3>5. Continue Working with Our Team Throughout the Lifetime of Your Loan</h3>
                <p>After you close on your loan, you&#39;ll have full access to our team and resources throughout the lifespan of your loan, like easy access to your loan information and paperwork as well as mortgage advice from our loan officers. If your financial situation ever changes, feel free to update your payment schedule to better accommodate your new finances. Our team is proud to offer continued service to our clients, from their first loan application to the very last mortgage payment.</p>
            </Col>
        </Row>
      </Container>
      <div className="my-5 pb-2 px-0">
        <HowItWorks />
      </div>
      <FaqAccordion />
      <Col sm={12} className="mt-5 pt-5 px-0">
        <RequestCallback className="mt-5" />
      </Col>
      <Col sm={12}>
        <Subscribe />
      </Col>
  </div>
  );
}
