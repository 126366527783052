import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import StyledHero from "../components/StyledHero";
import Banner from "../components/Banner";
import HowItWorks from "../components/HowItWorks";
import FaqAccordion from "../components/FaqAccordion";
import Subscribe from "../components/Subscribe";
import RequestCallback from "../components/RequestCallback";

import InvestmentImg from "../images/investment-property-loans-banner.jpg";

export default function Investment({
  isSWMC,
  setLoanPurpose,
  setModalShow,
  setOfferLoanType,
}) {
  return (
    <div id="productLoans">
      <Helmet>
        <title>Investment Property Loans | Sun West</title>
        <meta
          name="description"
          content="Purchasing a home to resell or rent out? Investment property loans just may be what you&#039;re looking for!"
        />
      </Helmet>
      <StyledHero img={InvestmentImg} bgPosition="center center">
        <Banner title="Investment Property Loans" headerFooter={true}
          isSWMC={isSWMC}
          setLoanPurpose={setLoanPurpose}
          setModalShow={setModalShow}
          setOfferLoanType={setOfferLoanType}></Banner>
      </StyledHero>
      <Container>
        <Row>
          <Col id="page-wrapper" className="pt-5">
            <h2>What is an Investment Property Loan?</h2>
            <p>
              An investment property is a property that is not your primary
              residence and has been purchased with intent to generate revenue,
              either through the future resale of the property, rental income or
              both.
            </p>
            <p>
              An investment property can be a long-term project or an
              intentional short-term investment where real estate is purchased,
              remodeled or renovated, and sold at a profit.
            </p>
            <p>&nbsp;</p>
            <h2>Tips for Buying an Investment Property:</h2>
            <p>
              It is important to be patient and well-informed about the market
              value of the property you wish to purchase. Selecting real estate
              that is more likely to increase in value is the most important
              decision you will make, therefore, buying at the right price is
              crucial.
            </p>
            <p>
              Before you purchase the home, have a professional building
              inspector perform an in-depth inspection of the property to find
              any potential problems as this may hinder your profit potential.
              Thereafter, it is recommended to do an in-depth inspection
              annually.
            </p>
            <p>
              Loans for investment properties usually accompany a higher
              interest rate. Be prepared to put down at least 20 percent of the
              property price as a down payment on the loan and ensure that your
              credit is in good standing.
            </p>
            <p>&nbsp;</p>
            <h2>Why should you choose Sun West?</h2>
            <p>
              Our team offers a 24-hour loan center for future home owners who
              don&#39;t have time to apply for a loan during regular business hours.
              We offer quick loan approvals and provide our clients with
              suitable loan options for their needs and financial situations.
              Our team members are well-versed in investment property loan rules
              and requirements and will ensure that you are knowledgeable about{" "}
              <Link to="../our-mortgage-process">our mortgage process</Link> prior
              to committing to a loan.
            </p>
            <p>
              For any questions about the investment property loan process or
              any of our other home loan services, give our team at Sun West a
              call to speak to a local team member who can answer all of your
              home loan questions.{" "}
              <Link to="../contact-us">Contact us today!</Link>
            </p>
          </Col>
        </Row>
      </Container>
      <div className="my-5 pb-2 px-0">
        <HowItWorks />
      </div>
      <FaqAccordion />
      <Col sm={12} className="mt-5 pt-5 px-0">
        <RequestCallback className="mt-5" />
      </Col>
      <Col sm={12}>
        <Subscribe />
      </Col>
    </div>
  );
}
