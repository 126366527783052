import React from 'react';
import { Navbar, Container } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styled from 'styled-components'



const StyleWrapper = styled.div`

.swmcNavbar{
  position: absolute;
  top: 0;
  width: 100%;

}
.headerText{
  position: absolute;
left: 20%;
font-size: 32px;
color: #083f88;
}

@media(max-width: 420px){
  .headerText{
  left: 40% !important;
  font-size: 28px;
  }
}
@media(max-width: 992px){
  .headerText{
  left: 30%;
  font-size: 28px;
  }
}

`
const NavDesktop = ({
  
}) => {
  return (
    <StyleWrapper>
    <Navbar bg="light" variant="light" className='swmcNavbar'>
    <Container>
      <Navbar.Brand href="https://swmc.com">
        <img
          alt=""
          src="./assest/NewSunWestLogo-1.png"
          style={{width: '100px'}}
          className="d-inline-block align-top"
        />
      </Navbar.Brand>

      <Navbar.Brand className="headerText" >
     
     VA Loans
      </Navbar.Brand>

      
    </Container>
  </Navbar>
  </StyleWrapper>
  );
};



export default NavDesktop;
