import React from "react";

import "../css/subscribe.css"

export default function Subscribe() {
  return (
    <div
    id="subscribe-wrapper"
      className="fl-builder-content fl-builder-content-972 fl-builder-global-templates-locked py-3"
      data-post-id="972"
      data-type="part"
    >
      {/* <div
        className="fl-row fl-row-full-width fl-row-bg-color fl-node-5beaa363cf8c0"
        data-node="5beaa363cf8c0"
      >
        <div className="fl-row-content-wrap">
          <div className="uabb-row-separator uabb-top-row-separator"></div>
          <div className="fl-row-content fl-row-fixed-width fl-node-content">
            <div
              className="fl-col-group fl-node-5beaa363cf7a7 fl-col-group-custom-width"
              data-node="5beaa363cf7a7"
            >
              <div
                className="fl-col fl-node-5beaa363cf81a"
                data-node="5beaa363cf81a"
              >
                <div className="fl-col-content fl-node-content">
                  <div
                    id="home-contact"
                    className="fl-module fl-module-pp-gravity-form fl-node-5beaa363cf889"
                    data-node="5beaa363cf889"
                  >
                    <div className="fl-module-content fl-node-content">
                      <div className="pp-gf-content">
                        <div
                          className="gf_browser_chrome gform_wrapper gf_simple_horizontal_wrapper"
                          id="gform_wrapper_1"
                        >
                          <form
                            method="post"
                            encType="multipart/form-data"
                            target="gform_ajax_frame_1"
                            id="gform_1"
                            className="gf_simple_horizontal"
                            action="/"
                          >
                            <input
                              type="hidden"
                              className="gforms-pum"
                              value='{"closepopup":false,"closedelay":0,"openpopup":false,"openpopup_id":1367}'
                            />
                            <div className="gform_body">
                              <ul
                                id="gform_fields_1"
                                className="gform_fields top_label form_sublabel_below description_below"
                              >
                                <li
                                  id="field_1_3"
                                  className="gfield gf_inline gfield_html gfield_html_formatted gfield_no_follows_desc field_sublabel_below field_description_below gfield_visibility_visible"
                                >
                                  <h4 className="form_title">GET CONNECTED</h4>
                                </li>
                                <li
                                  id="field_1_1"
                                  className="gfield gf_inline gfield_contains_required field_sublabel_below field_description_below gfield_visibility_visible"
                                >
                                  <label className="gfield_label" for="input_1_1">
                                    <span className="gfield_required">*</span>
                                  </label>
                                  <div className="ginput_container ginput_container_text">
                                    <input
                                      name="input_1"
                                      id="input_1_1"
                                      type="text"
                                      value=""
                                      className="large"
                                      tabIndex="100"
                                      placeholder="FIRST NAME*"
                                      aria-required="true"
                                      aria-invalid="false"
                                    />
                                  </div>
                                </li>
                                <li
                                  id="field_1_2"
                                  className="gfield gf_inline gfield_contains_required field_sublabel_below field_description_below gfield_visibility_visible"
                                >
                                  <label className="gfield_label" for="input_1_2">
                                    <span className="gfield_required">*</span>
                                  </label>
                                  <div className="ginput_container ginput_container_email">
                                    <input
                                      name="input_2"
                                      id="input_1_2"
                                      type="text"
                                      value=""
                                      className="large"
                                      tabIndex="101"
                                      placeholder="EMAIL*"
                                      aria-required="true"
                                      aria-invalid="false"
                                    />
                                  </div>
                                </li>
                              </ul>
                            </div>
                            <div className="gform_footer top_label">
                              {" "}
                              <input
                                type="submit"
                                id="gform_submit_button_1"
                                className="gform_button button"
                                value="SUBSCRIBE"
                                tabIndex="102"
                                onClick='if(window["gf_submitting_1"]){return false;}  window["gf_submitting_1"]=true;  '
                                onKeyPress='if( event.keyCode == 13 ){ if(window["gf_submitting_1"]){return false;} window["gf_submitting_1"]=true;  jQuery("#gform_1").trigger("submit",[true]); }'
                              />
                              <input
                                type="hidden"
                                name="gform_ajax"
                                value="form_id=1&amp;title=&amp;description=&amp;tabIndex=100"
                              />
                              <input
                                type="hidden"
                                className="gform_hidden"
                                name="is_submit_1"
                                value="1"
                              />
                              <input
                                type="hidden"
                                className="gform_hidden"
                                name="gform_submit"
                                value="1"
                              />
                              <input
                                type="hidden"
                                className="gform_hidden"
                                name="gform_unique_id"
                                value=""
                              />
                              <input
                                type="hidden"
                                className="gform_hidden"
                                name="state_1"
                                value="WyJbXSIsIjkyNTc0ZDc2MWUzODRiOGFiMDU5Zjc3MjAzODBkNzQwIl0="
                              />
                              <input
                                type="hidden"
                                className="gform_hidden"
                                name="gform_target_page_number_1"
                                id="gform_target_page_number_1"
                                value="0"
                              />
                              <input
                                type="hidden"
                                className="gform_hidden"
                                name="gform_source_page_number_1"
                                id="gform_source_page_number_1"
                                value="1"
                              />
                              <input
                                type="hidden"
                                name="gform_field_values"
                                value=""
                              />
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      <div
        className="fl-row fl-row-full-width fl-row-bg-color fl-node-5beaa36ec9bfc"
        data-node="5beaa36ec9bfc"
      >
        <div className="fl-row-content-wrap">
          <div className="uabb-row-separator uabb-top-row-separator"></div>
          <div className="fl-row-content fl-row-fixed-width fl-node-content">
            <div
              className="fl-col-group fl-node-5beaa36ec9b52"
            >
              <div
                className="fl-col fl-node-5beaa36ec9b8d"
              >
                <div className="fl-col-content fl-node-content">
                  <div
                    className="fl-module fl-module-pp-image fl-node-5beaa36ec9bc5"
                  >
                    <div className="fl-module-content fl-node-content">
                      <div className="pp-photo-container">
                        <div
                          className="pp-photo pp-photo-align-center pp-photo-align-responsive-default"
                        >
                          <div className="pp-photo-content ">
                            <div className="pp-photo-content-inner">
                              <img
                                className="pp-photo-img wp-image-2187 size-full"
                                src="./assest/NewSunWestLogo-1.png"
                                alt="NewSunWestLogo-1"
                              />
                              <div className="pp-overlay-bg"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
