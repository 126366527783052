import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import Image from "react-bootstrap/Image";
import "../css/FaqAccordion.css";

import ImgFAQ from "../images/apply-for-loan-today-image.jpg";

export default function FaqAccordion() {
  return (
    <Container fluid className="px-3 px-sm-3 px-md-4">
      <Row className="g-0">
        <Col sm={12} md={6} className="FaqAccordionCol mb-5 mrgncustom">
          <h2>Frequently Asked Questions</h2>
          <Accordion className="faq-accordion">
            <Card>
              <Accordion.Collapse eventKey="0" className="faq-answer">
                <Card.Body>
                First, you will need to submit a mortgage application with us, either in person, by phone, or using our easy-to-use online form, whichever makes you feel more comfortable. One of our licensed loan officers will get in touch with you and discuss your mortgage needs and objectives with you. Next, we will check your credit scores and request you to submit the necessary supporting documents so that we can verify your identity, the source of your income, and your current debt for underwriting purposes. An appraisal will also be performed on your selected property. Once you have submitted all the documents and your loan is approved with no outstanding items, we will then prepare the closing documents so that you can get ready to sign your loan package. After your loan is funded, you are on your way to move into your new home.
                </Card.Body>
              </Accordion.Collapse>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="0"
                className="faq-question"
              >
                What does getting a mortgage involve?
              </Accordion.Toggle>
            </Card>
            <Card>
              <Accordion.Collapse eventKey="1" className="faq-answer">
                <Card.Body>
                No, Sun West does not charge an application fee for you to apply for a mortgage.
                </Card.Body>
              </Accordion.Collapse>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="1"
                className="faq-question"
              >
                Does it cost anything to apply for a mortgage?
              </Accordion.Toggle>
            </Card>
            <Card>
              <Accordion.Collapse eventKey="2" className="faq-answer">
                <Card.Body>
                Sun West has one of the fastest turnaround times in the industry, and our average time to close loans, even loans with FICO scores below 620, is 14 calendar days, but every borrower’s situation is different, and due to documentation requirements and varying response times from the borrower, the average time to close a loan may be higher.
                </Card.Body>
              </Accordion.Collapse>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="2"
                className="faq-question"
              >
                How long does it take to close my loan?
              </Accordion.Toggle>
            </Card>
            <Card>
              <Accordion.Collapse eventKey="3" className="faq-answer">
                <Card.Body>
                A mortgage refinance refers to obtaining a new loan for the purpose of lowering your mortgage payments, converting your existing loan into a more affordable or manageable loan, or getting cash out on available equity on your home. There may be a minimum waiting period from the date you closed your previous mortgage, based on the type of refinance you are applying for. Sun West also recommends that you check with your existing lender regarding any prepayment penalties. There will be fees involved when refinancing your home, although you may have the option of rolling these costs into your new loan. Please call one of our licensed loan officers at-&nbsp;<a href="tel:+1(855)418-3362">855.418.3362</a>&nbsp;to discuss whether a refinance is right for you.
                </Card.Body>
              </Accordion.Collapse>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="3"
                className="faq-question"
              >
                What is a refinance?
              </Accordion.Toggle>
            </Card>
            <Card>
              <Accordion.Collapse eventKey="4" className="faq-answer">
                <Card.Body>
                Most types of mortgages require a minimum amount of down payment, except for VA and USDA programs. You may also be eligible for down payment assistance programs that can help you towards minimum down payment requirement on some of the loan programs. On Conventional Mortgages, your lender will require you to pay a Private Mortgage Insurance (PMI) premium as part of your monthly payments if you put down less than 20% of the purchase price of the property. There are government loan programs available with less stringent down payment requirements as well, such as an FHA loan that will require only a 3.5% down payment, but again, these programs also require mortgage insurance premiums. Finally, keep in mind that the amount of down payment you put down for a house will also affect your Loan-to-Value (LTV) ratio, which could then affect the amount of loan you are able to qualify for, or whether or not you will qualify at all.
                </Card.Body>
              </Accordion.Collapse>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="4"
                className="faq-question faq-last"
              >
                What amount of down payment is required in order <br />
                to obtain a mortgage?
              </Accordion.Toggle>
            </Card>
          </Accordion>
        </Col>
        <Col md={6}>
          <Image src={ImgFAQ} fluid className="rounded shadow" alt="Man signing a contract." />
        </Col>
      </Row>
    </Container>
  );
}