import React from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import StyledHero from "../components/StyledHero";
import Banner from "../components/Banner";
import HowItWorks from "../components/HowItWorks";
import FaqAccordion from "../components/FaqAccordion";
import Subscribe from "../components/Subscribe";
import RequestCallback from "../components/RequestCallback";

import ReverseImg from "../images/reverse-mortgage-hecm-banner.jpg";

export default function Reverse({
  isSWMC,
  setLoanPurpose,
  setModalShow,
  setOfferLoanType,
}) {
  return (
    <div id="productLoans">
      <Helmet>
        <title>Reverse Mortgage / HECM | Sun West</title>
        <meta
          name="description"
          content="A reverse mortgage allows you to convert part of the equity in your home into cash without having to sell your home or pay additional bills."
        />
      </Helmet>
      <StyledHero img={ReverseImg} bgPosition="center center">
        <Banner
          title="Reverse Mortgage / HECM"
          headerFooter={true} 
          isSWMC={isSWMC}
          setLoanPurpose={setLoanPurpose}
          setModalShow={setModalShow}
          setOfferLoanType={setOfferLoanType}
        ></Banner>
      </StyledHero>
      <Container>
        <Row>
          <Col id="page-wrapper" className="pt-5">
              <h2>What is a reverse mortgage?</h2>
              <p>
                A reverse mortgage is a type of home equity loan that allows you
                to convert part of the equity in your home into cash without
                having to sell your home or pay additional monthly bills.
                Reverse mortgages are typically catered towards older
                homeowners, but they have also become a great retirement
                planning tool for many homeowners. This type of mortgage does
                not need to be repaid until the borrower passes away, sells the
                home, or permanently moves out.&nbsp;Borrower must maintain the
                house and pay property taxes and homeowners insurance.
              </p>
              <p>
                Home Equity Conversion Mortgage (HECM) is a type of reverse
                mortgage insured by FHA.
              </p>
              <h2>How do reverse mortgages work?</h2>
              <p>
                Unlike a traditional mortgage that borrowers pay back each
                month, a reverse mortgage makes payments to borrowers. Borrowers
                can get these payments in a lump sum, as regular monthly
                payments, as a line of credit, or as a combination of monthly
                payments with a line of credit.&nbsp; There are several factors
                that determine the amount of funds you can receive from a
                reverse mortgage, such as your age, value of home, and interest
                rate.
              </p>
              <p>&nbsp;</p>
              <h2>Who would benefit from a reverse mortgage?</h2>
              <p>A reverse mortgage makes senses for individuals who:</p>
              <ul>
                <li>
                  Are age 62 and older and own a home or have small mortgages.
                </li>
                <li>Don&#39;t plan to move.</li>
                <li>Can afford the cost of maintaining their home.</li>
                <li>
                  Want to access the equity in their home to supplement their
                  income or have money available for a rainy day.
                </li>
              </ul>
              <p>&nbsp;</p>
              <h2>What are the advantages of Reverse Mortgage?</h2>
              <ul>
                <li>Does not require monthly payments from the borrower.</li>
                <li>
                  Proceeds can be used to pay off debt or settle unexpected
                  expenses.
                </li>
                <li>Funds can improve monthly cash flow.</li>
              </ul>
              <p>&nbsp;</p>
              <p>
                However, note that&nbsp;Consumers with a Reverse Mortgage can
                lose their home if they fail to comply with the loan terms,
                which include, among other terms, paying property taxes,
                homeowners insurance and HOA dues (if applicable) in time,
                maintaining the property, etc.
              </p>
              <p>&nbsp;</p>
              <h2>Why should you choose Sun West?</h2>
              <p>
                Our team offers a 24-hour loan center for future home owners who
                don&#39;t have time to apply for a loan during regular business
                hours. We offer quick loan approvals and provide our clients
                with suitable loan options for their needs and financial
                situations. Our team members are well-versed in reverse mortgage
                / HECM loan rules and requirements and will ensure that you are
                knowledgeable about{" "}
                <Link to="../our-mortgage-process">our mortgage process</Link> prior
                to committing to a loan.
              </p>
              <p>
                For any questions about the reverse mortgage / HECM loan process
                or any of our other home loan services, give our team at Sun
                West a call to speak to a local team member who can answer all
                of your home loan questions.{" "}
                <Link to="../contact-us">Contact us today!</Link>
              </p>
          </Col>
        </Row>
      </Container>
      <div className="my-5 pb-2 px-0">
        <HowItWorks />
      </div>
      <FaqAccordion />
      <Col sm={12} className="mt-5 pt-5 px-0">
        <RequestCallback className="mt-5" />
      </Col>
      <Col sm={12}>
        <Subscribe />
      </Col>
    </div>
  );
}
