import React from "react";

export const imagePath = process.env.REACT_APP_CDN + 'common/images/wholesale-websites'

export const thumbnailImagePath = 'https://resources.swmc.com/swmc-images/thumbnails'




export const yTVideoArray = [
    {
    "id" : "_iDhbeQCAWc",
    "link": "https://www.youtube.com/watch?v=_iDhbeQCAWc",
    "thumbnail": "https://resources.swmc.com/swmc-images/thumbnails/_iDhbeQCAWc-MQ.jpg",
    "title": "Pleasant Experience With Sun West"
    },
    {
    "id" : "OZUhCGxC28Y",
    "link": "https://www.youtube.com/watch?v=OZUhCGxC28Y",
    "thumbnail": "https://resources.swmc.com/swmc-images/thumbnails/OZUhCGxC28Y-MQ.jpg",
    "title": "Never Had an Easier Transaction"
    },

    {
    "id" : "shorts/WUv__4IZSyM",
    "link": "https://www.youtube.com/shorts/WUv__4IZSyM",
    "thumbnail": "https://resources.swmc.com/swmc-images/thumbnails/Part-2_Youtube.jpg",
    "title": "Quick and Convenient Loan Closing"
    },

    {
        "id" : "YvP35Bpv8JA",
        "link": "https://www.youtube.com/watch?v=YvP35Bpv8JA",
        "thumbnail": "https://resources.swmc.com/swmc-images/thumbnails/YvP35Bpv8JA-HD.jpg",
        "title": "Excellent Loan Servicing"
        },



]


export const generateYtThumbnail = (idPassed) => `${thumbnailImagePath}/${idPassed}.jpg`

export const generateYtVideoLink = (idPassed) => `https://www.youtube.com/watch?v=${idPassed}&authuser=0`


export const dragElement = (elmnt, holderEle) => { // (myDIv, myDiveader is holding element for this for this)
    // console.log('DragFn:', elmnt)
    var pos1 = 0, pos2 = 0, pos3 = 0, pos4 = 0;
    if (document.getElementById(elmnt.id + "header")) {
        /* if present, the header is where you move the DIV from:*/
        document.getElementById(elmnt.id + "header").onmousedown = dragMouseDown;
    } else if (holderEle && holderEle.length > 0) {
        /* if present, the header is where you move the DIV from:*/
        holderEle[0].onmousedown = dragMouseDown;
    } else {
        /* otherwise, move the DIV from anywhere inside the DIV:*/
        elmnt.onmousedown = dragMouseDown;
    }

    function dragMouseDown(e) {
        e = e || window.event;
        e.preventDefault();
        // get the mouse cursor position at startup:
        pos3 = e.clientX;
        pos4 = e.clientY;
        document.onmouseup = closeDragElement;
        // call a function whenever the cursor moves:
        document.onmousemove = elementDrag;
    }

    function elementDrag(e) {
        e = e || window.event;
        e.preventDefault();
        // calculate the new cursor position:
        pos1 = pos3 - e.clientX;
        pos2 = pos4 - e.clientY;
        pos3 = e.clientX;
        pos4 = e.clientY;
        // set the element's new position:
        elmnt.style.top = (elmnt.offsetTop - pos2) + "px";
        elmnt.style.left = (elmnt.offsetLeft - pos1) + "px";
    }

    function closeDragElement() {
        /* stop moving when mouse button is released:*/
        document.onmouseup = null;
        document.onmousemove = null;
    }
}
