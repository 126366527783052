import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faShoppingBag } from "@fortawesome/free-solid-svg-icons";
import { faGraduationCap } from "@fortawesome/free-solid-svg-icons";
import styled from 'styled-components'

import "../css/headerFooter.css";

export default function HeaderFooter({isSWMC}) {
  return (
    <StyleWrapper>
    <div>
      <div
        className="fl-row fl-row-full-width fl-row-bg-color fl-node-5bed330a2c431"
        data-node="5bed330a2c431"
      >
        <div className={"fl-row-content-wrap" + ' '+ (isSWMC ? 'isSWMC' : null)}>
          <div className="uabb-row-separator uabb-top-row-separator"></div>
          <div className="fl-row-content fl-row-fixed-width fl-node-content">
            <div
              className="fl-col-group fl-node-5bed21b534818 fl-col-group-equal-height fl-col-group-align-top"
              data-node="5bed21b534818"
            >
              <div
                className="fl-col fl-node-5bed21b534852 fl-col-small"
                data-node="5bed21b534852"
              >
                <div className="fl-col-content fl-node-content">
                  <div
                    className="fl-module fl-module-pp-infobox fl-node-5bed21b5348fa"
                    data-node="5bed21b5348fa"
                  >
                    <div className="fl-module-content fl-node-content">
                      <div className="pp-infobox-wrap">
                        <div className="pp-infobox layout-5">
                          <div className="pp-icon-wrapper animated">
                            <div className="pp-infobox-icon">
                              <div className="pp-infobox-icon-inner">
                                <span className="pp-icon fas fa-check">
                                  <FontAwesomeIcon
                                    icon={faCheck}
                                    className="react-icons"
                                  />
                                </span>
                              </div>
                            </div>
                          </div>

                          <span className="pp-infobox-title-prefix"></span>

                          <div className="pp-infobox-title-wrapper">
                            <h3 className="pp-infobox-title">
                              Customer Experience
                            </h3>
                          </div>
                          <div className="pp-infobox-description">
                            <div className="pp-description-wrap">
                              <p>
                                At Sun West, we dedicate ourselves to providing
                                a world-class customer experience.
                              </p>{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="fl-col fl-node-5bed21b534889 fl-col-small"
                data-node="5bed21b534889"
              >
                <div className="fl-col-content fl-node-content">
                  <div
                    className="fl-module fl-module-pp-infobox fl-node-5bed21b534934"
                    data-node="5bed21b534934"
                  >
                    <div className="fl-module-content fl-node-content">
                      <div className="pp-infobox-wrap">
                        <div className="pp-infobox layout-5">
                          <div className="pp-icon-wrapper animated">
                            <div className="pp-infobox-icon">
                              <div className="pp-infobox-icon-inner">
                                <span className="pp-icon fas fa-shopping-bag">
                                  <FontAwesomeIcon
                                    icon={faShoppingBag}
                                    className="react-icons"
                                  />
                                </span>
                              </div>
                            </div>
                          </div>

                          <span className="pp-infobox-title-prefix"></span>

                          <div className="pp-infobox-title-wrapper">
                            <h3 className="pp-infobox-title">
                              Working For You
                            </h3>
                          </div>
                          <div className="pp-infobox-description">
                            <div className="pp-description-wrap">
                              <p>
                                All of our loan experts are dedicated to helping
                                you find the perfect loan for your needs and
                                goals.
                              </p>{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="fl-col fl-node-5bed21b5348c1 fl-col-small"
                data-node="5bed21b5348c1"
              >
                <div className="fl-col-content fl-node-content">
                  <div
                    className="fl-module fl-module-pp-infobox fl-node-5bed21b53496d"
                    data-node="5bed21b53496d"
                  >
                    <div className="fl-module-content fl-node-content">
                      <div className="pp-infobox-wrap">
                        <div className="pp-infobox layout-5">
                          <div className="pp-icon-wrapper animated">
                            <div className="pp-infobox-icon">
                              <div className="pp-infobox-icon-inner">
                                <span className="pp-icon fas fa-graduation-cap">
                                  <FontAwesomeIcon
                                    icon={faGraduationCap}
                                    className="react-icons"
                                  />
                                </span>
                              </div>
                            </div>
                          </div>

                          <span className="pp-infobox-title-prefix"></span>

                          <div className="pp-infobox-title-wrapper">
                            <h3 className="pp-infobox-title">
                              Mortgage Expertise
                            </h3>
                          </div>
                          <div className="pp-infobox-description">
                            <div className="pp-description-wrap">
                              <p>
                                Our parent company Sun West has passed down
                                nearly 40 years of experience to everyone at Sun
                                West through excellent leadership and loan
                                capabilities.
                              </p>{" "}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </StyleWrapper>
  );
}

const StyleWrapper = styled.div`
.isSWMC .pp-infobox-title {
  color: rgb(8, 63, 136) !important;
}

.isSWMC .pp-infobox-description p {
  color: rgb(8, 63, 136) !important;
}

.fl-row-content-wrap.isSWMC {
  background: rgb(255, 255, 255) linear-gradient(90deg, rgba(0, 170, 228, 0.23) 0%, rgba(0, 170, 228, 0) 44.89%, rgba(0, 170, 228, 0.52) 99.06%) repeat scroll 0% 0% !important;
}

.isSWMC .pp-infobox-icon span {
  background: #00aae4 !important;
}

` 
