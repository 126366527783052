import React from "react";
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import './MloCards.css';


function MloCard(props) {

    return (
        <>
        <Col xs={12} sm={6} lg={3}>
            <div className="mlo-card">
                <div className="team-one"> 
                    <div className="team-thumb img-circle">    
                        <Image className="img-fluid img-whp" src={props.lodata.imgUrl} alt={props.lodata.name + " image"}/>
                    </div >
                    <div className="team-details">
                        <h3>{props.lodata.name}</h3>
                        <h6>NMLS ID: {props.lodata.nmls}</h6>
                        <a className="btn btn-default ulockd-btn-thm2 rounded bioBtn" href={props.lodata.profileUrl} rel='noreferrer noopener' target="_blank"><span>SEE FULL BIO</span></a>
                    </div>
                </div>
            </div>
        </Col>
        </>
    )
}

export default MloCard