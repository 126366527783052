import React from "react";
import { Helmet } from "react-helmet";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import StyledHero from "../components/StyledHero";
import Banner from "../components/Banner";
import HowItWorks from "../components/HowItWorks";
import FaqAccordion from "../components/FaqAccordion";
import Subscribe from "../components/Subscribe";
import RequestCallback from "../components/RequestCallback";
import VideoCarousel from "../components/VideoCarousel";
import "../css/va.css"

import VaImg from "../images/va-loans-banner.jpg";

export default function Va({
  isSWMC,
  setLoanPurpose,
  setModalShow,
  setOfferLoanType,
}) {
  return (
    <div id="vaLoans" className={isSWMC ? "mtMinus" : null}>
      <div id="productLoans">
        <Helmet>
          <title>VA Loans | Sun West</title>
          <meta
            name="description"
            content="Veterans Affairs (VA) mortgages are provided to active-duty military and veterans to make the home buying process easier. Contact us to see if you qualify!"
          />
        </Helmet>
        <StyledHero img={VaImg} bgPosition="center center">
          <Banner
            title="VA Loans"
            headerFooter={true}
            isSWMC={isSWMC}
            setLoanPurpose={setLoanPurpose}
            setModalShow={setModalShow}
            setOfferLoanType={setOfferLoanType}
          ></Banner>
        </StyledHero>
        <Container>
          <Row>
            <Col id="page-wrapper" className="pt-5">
              <h2>What are VA Loans?</h2>
              <p>
                Guaranteed by the U.S. Department of Veteran Affairs, the VA
                loan program is designed to assist both current active duty
                military members and veterans achieve homeownership. Eligible
                borrowers for this program are allowed to borrow at a higher
                percentage of the home value and the loan is guaranteed by the
                government against default.
              </p>
              <p>&nbsp;</p>
              <h2>What are the Advantages of VA Loans?</h2>
              <p>
                There is no minimum down payment required and no monthly
                mortgage insurance payments. The minimum credit score required
                is as low as 500.
              </p>
              <p>&nbsp;</p>
              <h2>Eligibility</h2>
              <p>
                Active members of the military, veterans, and members of the
                National Guard are qualified to apply for VA loans. Spouses of
                service-connected disability or deceased military members may
                also apply for these types of loans.
              </p>
              <p>&nbsp;</p>
              <h2>Why should you choose Sun West?</h2>
              <p>
                Our team offers a 24-hour loan center for future home owners who
                don&#39;t have time to apply for a loan during regular business
                hours. We offer quick loan approvals and provide our clients
                with suitable loan options for their needs and financial
                situations. Our team members are well-versed in&nbsp;VA loan
                rules and requirements and will ensure that you are
                knowledgeable about
                {/* SWMC-866 */}
                {/* <a href="https://www.swmcretail.com/our-mortgage-process">our mortgage process</a> */}
                {' '}our mortgage process
                prior to committing to a loan.
              </p>
              <p>
                For any questions about the VA loan process or any of our other
                home loan services, give our team at Sun West a call to speak to
                a local team member who can answer all of your home loan
                questions. <button className="btn-link" onClick={() => {
                  setLoanPurpose('');
                  setModalShow(true);
                  setOfferLoanType('VA');
                }}>Contact us today!</button>
              </p>
            </Col>
          </Row>
        </Container>
        <div className="my-5 pb-2 px-0">
          <HowItWorks />
        </div>
        <FaqAccordion />
        <Col sm={12} className="mt-5 pt-5 px-0">
          <RequestCallback isSWMC={isSWMC} className="mt-5" />
        </Col>
        {!isSWMC &&
        <Col sm={12}>
          <Subscribe />
        </Col>
        }

        {isSWMC && <VideoCarousel title= "Video Testimonials" 
        // subheading = "TESTIMONIALS"
        />}
      </div>
    </div>
  );
}
